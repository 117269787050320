import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CodesPrint from "./CodesPrint";
import db from "./db";
import { useOutletContext } from "react-router-dom";

const errorHandler = er => alert(er);

export function Home() {
    const { unPrintedSet, setUnPrintedSet } = useOutletContext();
    const [bDialogOpen, setDialogOpen] = useState(false);
    const openDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);

	const readFile = ev => new Promise((resolve, reject) => {
        const reader = new FileReader();
    
        reader.readAsText(ev.target.files[0]);
        reader.onerror = () => reject(reader.error);
        reader.onload = () => {
            if (reader.result.startsWith('010467014289')) resolve(reader.result.split('\r\n'));
            else reject(new Error('Файл не является корректным (*.csv)'));
        };
    })
    .then(arr => db.post('_bulk_docs', { docs: arr.map(code => {
        let [ _id, hash ] = code.split('\u001d');
        return { _id, hash }
    }) })).then(res => {
        if (res.filter(code => code.ok).length === res.length) {
            const cises = res.map(code => code.id).concat(...unPrintedSet);

            return db.put('unprinted', { cises }).then(() => {
                setUnPrintedSet(new Set(cises));
                alert(res.length + ' код(ов) успешно загружены!')
            });
        }
        if (res.filter(code => code.error === 'conflict').length === res.length) throw new Error('Этот файл уже был загружен.');
        throw new Error(JSON.stringify(res, '', 2));
    }).catch(errorHandler);

    function printCodes() {
        console.log(Array.from(unPrintedSet));
    }

    return <>
        <CodesPrint { ...{ bDialogOpen, closeDialog }}/>
        <Box sx={{ m: 2 }}>
            <Button variant="contained" component="label" sx={{ m: 2 }}>
                Загрузить новые коды (файл order_UUID_gtin_quantity_X.csv)
                <input hidden accept=".csv" type="file" onChange={readFile} />
            </Button>
            <Typography sx={{ p: 2 }}>
                Количество ненапечатанных кодов: {unPrintedSet.size}
                <Button variant="contained" onClick={openDialog} sx={{ m: 2 }}>
                    Печать
                </Button>
            </Typography>
        </Box>
    </>;
}
