// import * as auth from "./auth";
// import EventBus from "../common/EventBus";
let token;

// const baseUrl = 'http://localhost:8080/https://markirovka.sandbox.crptech.ru/api/v3/true-api';
// const baseUrl = 'http://localhost:8080/https://markirovka.crpt.ru/api';
// const baseUrl = 'http://192.168.7.204:8080/api/v3/true-api';
const baseUrl = 'https://crpt.orenkontur.ru/api/v3/true-api';
// const baseUrl = '/api/v3/true-api';
// const baseUrl = 'https://markirovka.crpt.ru/api';

const errorLog = er => console.log(er);

const parseType = headers => headers.get('Content-Type').includes('/json') ? 'json' : 'text';
const readBodyResponse = response => response[parseType(response.headers)]()
  .then(data => {
    if (response.ok) return data;
    else throw({ data, response });
  });

const getHeaders = () => {
  const headers = { 'Content-Type': 'application/json;charset=utf-8' };
  if (token) headers.Authorization = 'Bearer ' + token;
  return headers;
}

const customFetch = (method, url, bodyObject) => fetch(baseUrl + url, {
  method,
  headers: getHeaders(),
  body: bodyObject ? JSON.stringify(bodyObject) : undefined
})
.then(response => {
// console.log(response);
  if (response.status == 401) return window.location.href = "/login";
  return readBodyResponse(response);
}).catch(errorLog);

const api = {
  get: (url) => customFetch('GET', url),
  post: (url, bodyObject) => customFetch('POST', url, bodyObject),
  fetch: (method, url, bodyObject) => customFetch(method, url, bodyObject),
  set: jwt => token = jwt
}

export default api;
