import React, { useState, useEffect, useRef } from "react";
import { DataGrid, ruRU, GridFooter } from '@mui/x-data-grid';
import { useOutletContext } from "react-router-dom";
import api from "./api";
import CodesDialog from "./CodesDialog.jsx";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import dictionary from "./dictionary";
import conf from "./conf";

const dateFormatRu = new Intl.DateTimeFormat("ru");
const entrieToParamStr = ([key, val]) => val ? '&' + key + '=' + (val == 'PRINTED' ? 'APPLIED' : val) : '';

export function Codes() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState();
    const { urlOpts, postBody, unPrintedSet } = useOutletContext();
    const [dialogOpen, setDialogOpen] = useState(false);
    const openDialog = () => setDialogOpen(true);
    const closeDialog = () => setDialogOpen(false);
    const dateFormatter = cell => dateFormatRu.format(new Date(Date.parse(cell.value)));
    const statusValueGetter = cell => dictionary.cisStatus[cell.value] || cell.value;
    const [selectionModel, setSelectionModel] = useState([]);
    
    const columns = [
        { field: 'emissionDate', headerName: 'Выпущен', width: 120,
            valueFormatter: dateFormatter, filterable: false },
        { field: 'cis', headerName: 'Код маркировки', width: 320 },
        { field: 'cisStatus', headerName: 'Статус', width: 110,
            valueGetter: statusValueGetter },
        { field: 'gtin', headerName: 'Код товара', width: 150 },
        { field: 'productName', headerName: 'Наименование товара', width: 440 }
           // , disableColumnMenu: true }
    ];

    function getStatus(row) {
        if (row.status != 'APPLIED') return row.status;
        return unPrintedSet.has(row.cis) ? 'APPLIED' : 'PRINTED';
    }
    
    useEffect(() => {
        const method = postBody.length ? 'POST' : 'GET';
        const url = postBody.length ? '/cises/info?pg=water' :
            "/cises/listV2?pg=water&order=DESC&orderColumn=emd" +
            Object.entries(urlOpts).map(entrieToParamStr).join('');

        setLoading(true);
        api.fetch(method, url, postBody.length ? postBody : undefined)
        .then(res => {
            // console.log(res);
            const resArr = res.results || res.map(item => ({ ...item.cisInfo }));
            let rowsArr = resArr.map(row => ({ ...row, cisStatus: row.status }));

            // if (urlOpts.cisStatus == "APPLIED" || urlOpts.cisStatus == "PRINTED") {
            //     rowsArr = rowsArr.filter(row => row.cisStatus == urlOpts.cisStatus);
            // }
            setRows(rowsArr);
            setLoading(false);
        });
    }, [urlOpts, postBody]);

    return <>
        <CodesDialog { ...{ dialogOpen, closeDialog, selectionModel, setSelectionModel, rows }}/>
        <div id='dataGridBorder' style={{ height: "80vh", margin: '8px' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                aria-label='Codes'
                loading={loading}
                components={{
                    ColumnMenu: CustomColumnMenu,
                    ColumnMenuIcon: FilterIcon,
                    Footer: CustomFooter
                }}
                componentsProps={{ footer: { openDialog, rowHas: rows.length } }}
                sx={{
                    '& .MuiDataGrid-menuIcon': {
                        width: 'auto',
                        visibility: 'visible'
                    },
                }}
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                checkboxSelection
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                    // console.log(newSelectionModel);
                }}
                selectionModel={selectionModel}
                getRowId={(row) => row.cis}
            />
        </div>
    </>
}

const FilterIcon = () => {
    const { urlOpts } = useOutletContext();
    const [marker, setMarker] = useState();
    const iconEl = useRef();

    useEffect(() => {
        const field = iconEl.current.closest('.MuiDataGrid-columnHeader').dataset.field;
        setMarker(field == 'emissionDate' ? 'emissionDateTo' : field);
    }, []);

    return <span ref={iconEl} style={{ display: 'flex' }} >{
        marker == 'productName' ? <FilterAltOffIcon /> :
        urlOpts[marker] ? <FilterAltIcon /> : <FilterAltOutlinedIcon />
    }</span>;
};

const ColumnMenuBase = props => {
    const { setUrlOpts, setPostBody } = useOutletContext();
    const { hideMenu, currentColumn } = props;

    const handleSubmit = ev => {
        let data, isDate;

        ev.preventDefault();
        if (ev.type === 'submit') data = new FormData(ev.currentTarget); // else clear with data == undefined
        if (data) isDate = data.get('dateFrom') && data.get('dateTo');

        switch (currentColumn.field) {
            case 'emissionDate':
                setUrlOpts(prev => ({ ...prev,
                    emissionDateFrom: isDate ? data.get('dateFrom') + 'T00:00:00.000Z' : undefined,
                    emissionDateTo: isDate ? data.get('dateTo') + 'T23:59:59.999Z' : undefined,
                }));
            break;

            case 'cis':
                // console.log(ev.type);
                setPostBody(data ? data.get('cis').split('\n') : conf.postBodyInit);

            default: setUrlOpts(prev => ({ ...prev,
                [currentColumn.field]: data?.get(currentColumn.field) || undefined
            }));
        }
        hideMenu(ev);
    };

    return <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
        {props.children}
        {currentColumn.field != 'cis' && <><br/><br/></>}
        <Button variant="contained" type='submit'>Применить</Button>
        <IconButton onClick={handleSubmit} aria-label="delete">
            <FilterAltOffIcon />
        </IconButton>
        <IconButton onClick={hideMenu}>
            <CloseIcon />
        </IconButton>
    </Box>;
}


const CustomColumnMenu = props => {//console.log(props);
    const { currentColumn } = props;
    const { urlOpts, postBody } = useOutletContext();
    const [cisVal, setCisVal] = useState(postBody.join('\n'));

    const readFile = ev => new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsText(ev.target.files[0]);
        reader.onerror = () => reject(reader.error);
        reader.onload = () => resolve(reader.result);
    }).then(fileText => Array.from(fileText
        .matchAll(/(01\d{14}21.{13,})((",)|\u001d)/g))
        .map(arr => arr[1].replace('""', '"')).join('\r\n')
    ).then(setCisVal, alert);

    switch (currentColumn.field) {
        case 'emissionDate':
            const nowStr = new Date().toISOString();
            const {
                emissionDateFrom = nowStr.slice(0, 8) + '01',
                emissionDateTo = nowStr
            } = urlOpts;

            return <ColumnMenuBase { ...props }>
                <TextField
                    type="date"
                    name='dateFrom'
                    defaultValue={emissionDateFrom.slice(0, 10)}
                    label={'С'}
                    variant="outlined"
                />
                <TextField
                    type="date"
                    name='dateTo'
                    defaultValue={emissionDateTo.slice(0, 10)}
                    label={'По'}
                    variant="outlined"
                    sx={{ mx: 1 }}
                />
            </ColumnMenuBase>;
        // break;

        case 'cis': return <ColumnMenuBase { ...props }>
            <TextField
                type="text"
                name={'cis'}
                label={'Код(ы) маркировки'}
                value={cisVal}
                variant="outlined"
                multiline
                maxRows={15}
                sx={{ width: 400 }}
                onChange={ev => setCisVal(ev.target.value)}
            />
            <br/><br/>
            <Button variant="contained" component="label" sx={{ mr: 2 }}>
                Загрузить
                <input hidden accept=".csv" type="file" onChange={readFile} />
            </Button>
        </ColumnMenuBase>;

        case 'productName': return <FilterFlusher/>

        default: return <ColumnMenuBase { ...props }>
            <FormControl fullWidth>
                <InputLabel>{currentColumn.headerName}</InputLabel>
                <Select
                    name={currentColumn.field}
                    label={currentColumn.headerName}
                    defaultValue={urlOpts[currentColumn.field] || ''}
                >
                    {Object.entries(dictionary[currentColumn.field]).map(
                        ([key, val]) => <MenuItem key={key} value={key}>{val}</MenuItem>
                    )}
                </Select>
            </FormControl>
        </ColumnMenuBase>
    }
}

const FilterFlusher = () => {
    const { setUrlOpts, setPostBody } = useOutletContext();
    useEffect(() => {
        setUrlOpts(conf.urlOptsInit);
        setPostBody(conf.postBodyInit);
    }, []);
}

const CustomFooter = props => {
    const { urlOpts, setUrlOpts } = useOutletContext();
    const [locVal, setLocVal] = useState(urlOpts.limit.toString().length - 1);
    const handleSliderChange = (ev, val) => setUrlOpts(prev => {
        return { ...prev, limit: 10 ** val };
    });

    useEffect(() => setLocVal(urlOpts.limit.toString().length - 1), [urlOpts]);

    return <Grid container>
        <Grid item xs sx={{
            display: 'flex',
            alignItems: 'center',
            borderTop: '1px solid rgba(224, 224, 224, 1)'
        }}>
            <Button
                variant="contained"
                onClick={props.openDialog}
                sx={{ ml: 1, mr: 3 }}
                disabled={!props.rowHas}
            >
                Действия
            </Button>
            Запрашивать строк:
            <Slider
                aria-label="CodesCount"
                value={locVal}
                max={4}
                marks={[ 1, 10, 100, '1k', '10k' ].map((label, value) => ({ label, value }))}
                onChange={ev => setLocVal(ev.target.value)}
                onChangeCommitted={handleSliderChange}
                sx={{ width: 150, mx: 2 }}
            />
        </Grid>
        <Grid item>
            <GridFooter/>
        </Grid>
    </Grid>
}
