import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, useRouteError } from "react-router-dom";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import SignInCert from './SignInCert';
import { AuthProvider, useAuth } from "./useAuth";
import { AppBar } from "./AppBar";
import { Home } from "./Home";
import { Stat } from "./Stat";
import { Codes } from "./Codes";
import { Test } from "./Test";
import conf from "./conf";
import db from "./db";

const Profile = () => <div>Tipa Profile</div>;
const Settings = () => <div>Tipa Settings</div>;

function ErrorBoundary() {
    let error = useRouteError();
    console.error(error);
    // Uncaught ReferenceError: path is not defined
    return <div>Dang!</div>;
}

const theme = createTheme({
    palette: {
        primary: {
            light: '#faf995',
            main: '#fcff25',
            dark: '#bdbf07',
            contrastText: 'unset',
        },
        secondary: {
            light: '#ff7961',
            main: '#5ca6dc',
            dark: '#ba000d',
            contrastText: '#000',
        },
    },
});

const App = () => <BrowserRouter>
    <AuthProvider>
       <ThemeProvider theme={theme}>
            <Routes>
                <Route path="/login" element={<SignInCert />} errorElement={<ErrorBoundary />} />
                <Route element={<MainLayout />}>
                    <Route path={"/"} element={<Home />} />
                    <Route path={"/stat"} element={<Stat />} />
                    <Route path={"/codes"} element={<Codes />} />
                    <Route path={"/test"} element={<Test />} />
                    <Route path={"/profile"} element={<Profile />} />
                    <Route path={"/settings"} element={<Settings />} />
                </Route>
            </Routes>
        </ThemeProvider>
    </AuthProvider>
</BrowserRouter>

export default App;

const MainLayout = () => {
    const { user } = useAuth();
    const [urlOpts, setUrlOpts] = useState(conf.urlOptsInit);
    const [postBody, setPostBody] = useState(conf.postBodyInit);
    const [unPrintedSet, setUnPrintedSet] = useState([]);
    const location = useLocation();

    useEffect(() => {
        if(location.pathname != "/codes") {
            setUrlOpts(conf.urlOptsInit);
            setPostBody(conf.postBodyInit);
        }
    }, [location]);

    useEffect(() => {
        db.get('unprinted').then(res => setUnPrintedSet(new Set(res.cises)));
    }, []);

    if (!user) return <Navigate to="/login" />;

    return (
        <div>
            <AppBar
                pages={[
                    { label: "Главная", path: "/" },
                    { label: "Статистика", path: "/stat" },
                    { label: "Коды", path: "/codes" },
                ]}
                settings={[
                    { label: "Профиль", path: "profile" },
                    { label: "Настройки", path: "settings" },
                ]}
            />
            <Outlet context={{
                urlOpts, setUrlOpts,
                postBody, setPostBody,
                unPrintedSet, setUnPrintedSet
            }} />
        </div>
    );
};