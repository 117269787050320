import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import db from "./db";
import { useOutletContext } from "react-router-dom";

export function Test() {
    const { unPrintedSet } = useOutletContext();
    const unPrintedArr = Array.from(unPrintedSet.keys());
    const [cisVal, setCisVal] = React.useState(/*unPrintedArr.join('\r\n')*/);

    const readFile = ev => new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.readAsText(ev.target.files[0]);
        reader.onerror = () => reject(reader.error);
        reader.onload = () => resolve(reader.result);
    }).then(fileText => Array.from(fileText
        .matchAll(/(01\d{14}21.{13,})((",)|\u001d)/g))
        .map(arr => arr[1].replace('""', '"')).join('\r\n')
    ).then(setCisVal, alert);

    const handleClick = () => {
        // console.log({ docs: cisVal.split('\r\n').map(id => ({ id })) });
        // cisVal && db.put('unprinted', { cises: Array.from(new Set(cisVal.split('\r\n').concat(...unPrintedSet))) });
        db.put('unprinted', { cises: [] });
    };

    const errorHandler = er => alert(er);

    React.useEffect(() => {
        // fetchGet('unprinted').then(res => res.json()).then(res => console.log(res));
    }, []);

  return (
    <Box sx={{ m: 2 }}>
        <TextField
            type="text"
            name={'cis'}
            label={'Код(ы) маркировки'}
            value={cisVal}
            variant="outlined"
            multiline
            maxRows={15}
            sx={{ width: 400 }}
            onChange={ev => setCisVal(ev.target.value)}
        />
        <Button variant="contained" component="label" sx={{ m: 2 }}>
            Загрузить
            <input hidden accept=".csv" type="file" onChange={readFile} />
        </Button>
        <Button variant="contained" onClick={handleClick}>
            Click {unPrintedSet.size}
        </Button>
    </Box>
  );
}
