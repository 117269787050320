import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';
import { blue } from '@mui/material/colors';
import CodesPrint from './CodesPrint';
import CodesApplied from './CodesApplied';
import CodesIntrodused from './CodesIntrodused';
import dictionary from "./dictionary";

const PaperComponent = props => <Draggable
    handle="#draggable-dialog-title"
    cancel={'[class*="MuiDialogContent-root"]'}
>
    <Paper {...props} />
</Draggable>

export default function CodesDialog(props) {
    const { dialogOpen, closeDialog, rows, selectionModel, setSelectionModel } = props;
    const [selectedStatus, setSelectedStatus] = useState();
    const [codeStatuses, setCodeStatuses] = useState({});
    const selectStatus = (status, cises) => {
        setSelectionModel(cises);
        setSelectedStatus(status);
    }

    useEffect(() => {
        let selectedRows, newCodeStatuses = {};

        if (selectionModel.length) {
            const codesObj = rows.reduce((obj, row) => ({ ...obj, [row.cis]: row }), {});
            selectedRows = selectionModel.map(row => codesObj[row]);
        } else setSelectionModel(rows.map(code => code.cis));

        (selectedRows || rows).forEach(row => {
            if (row.cisStatus in newCodeStatuses) newCodeStatuses[row.cisStatus].push(row.cis);
            else newCodeStatuses[row.cisStatus] = [row.cis];
        }, []);
        const statusesArr = Object.keys(newCodeStatuses);

        setSelectedStatus(statusesArr.length == 1 && statusesArr[0]);
        setCodeStatuses(newCodeStatuses);
    }, [dialogOpen]);

    return <Dialog
        open={dialogOpen}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
    >
        <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{ position: 'absolute', right: 12, top: 12, }}
        >
            <CloseIcon />
        </IconButton>
        {selectedStatus ? <StatusSelector status={selectedStatus} { ...props } /> : <>
            <DialogTitle
                id="draggable-dialog-title"
                sx={{ pr: 8, cursor: 'move' }}
            >
                Действия с выбранными кодами
            </DialogTitle>
            <DialogContent dividers>
                <Typography sx={{ p: 3 }}>
                    У выбранных кодов ({selectionModel.length}) разные статусы.
                    Выберите нужный
                </Typography>
                <List sx={{ pt: 0 }}>
                    {Object.entries(codeStatuses).map(([key, val]) => (
                        <ListItem button onClick={ev => selectStatus(key, val)} key={key}>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                    <AddIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={
                                dictionary.cisStatus[key] + ' (' + val.length + ')'
                            }/>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </>}
    </Dialog>
}

function StatusSelector(props) {
    const ruStatus = dictionary.cisStatus[props.status];

    switch (props.status) {
        case 'APPLIED': return <CodesApplied { ...{ ...props }} />
        case 'INTRODUCED': return <CodesIntrodused { ...props }  />

        default: return <DialogContent dividers>
            <Typography sx={{ p: 3 }}>
                Функионал для статуса "{ ruStatus }" ещё не реализован.
            </Typography>
        </DialogContent>
    }
}