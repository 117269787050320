import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import CadesUtils from "@avb56/cades-utils";
import CompanyIcon from './CompanyIcon.jsx';
import './SignInCert.css';
import { useAuth } from "./useAuth";
import api from "./api";

const dateFormatRu = new Intl.DateTimeFormat("ru");

const parseCertsArray = certsArray => certsArray.map(cer => {
  const infoObj = cer.info[0].split(', ').reduce((obj, pair) => {
    const [key, val] = pair.split('=');
    obj[key] = val && val.replace(/"/g, '');
    return obj;
  }, {});
  infoObj.from = dateFormatRu.format(new Date(Date.parse(cer.info[1])));
  infoObj.expire = dateFormatRu.format(new Date(Date.parse(cer.info[2])));
  infoObj.serial = cer.info[3];
  infoObj.cert = cer.certificate;
  // console.log(infoObj);
  return infoObj;
});

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function SignInCert() {

  const [certsList, setCertsList] = useState([]);
  const [selectedCert, setSelectedCert] = useState();
  const [submitOn, setSubmitOn] = useState();
  const [loading, setLoading] = useState();
  const { login } = useAuth();

  const onChangeCert = ev => {
    setSelectedCert(certsList[ev.target.closest('LI').dataset.optionIndex]);
    setSubmitOn(true);
  }

  const handleSubmit = ev => {
    ev.preventDefault();
    setSubmitOn(false);
    setLoading(true);
    api.get("/auth/key")
    .then(sault => CadesUtils.signMessage(sault.data, selectedCert.cert)
    .then(signed => api.post("/auth/simpleSignIn", { ...sault, data: signed })))
    .then(jwt =>{
      api.set(jwt.token);
      login(selectedCert);
    });
  };

  const renderOption = (props, option) => <Box component="li" {...props}>
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
      sx={{ my: 2 }}
    >
      <h4 style={{ margin: '8px 0' }}>
        <CompanyIcon color="secondary" uric={option.O} />
        {option.CN}
      </h4>
      <div>{option['ИНН ЮЛ'] && 'ИНН ЮЛ ' + option['ИНН ЮЛ']}</div>
      <div>{option.SN} {option.G}</div>
      <div>{option.T || null}</div>
      <div>{'ИНН ' + option['ИНН']}</div>
      <div>{'СНИЛС ' + option['СНИЛС']}</div>
      <div>{'Сертификат ' + option.serial}</div>
      <div>Действует с {option.from} по {option.expire}</div>
    </Grid>
  </Box>

  useEffect(() => {
    CadesUtils.getFinalCertsArray()
    .then(parseCertsArray)
    .then(setCertsList);
  }, []);

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className='crpt-logo'
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, color: 'primary.contrastText', bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ m: 2 }}>
            вход в систему
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1, width: 1, maxWidth: 400 }}
          >
            <Autocomplete
              fullWidth
              disableClearable
              id="combo-box-certs"
              options={certsList}
              onChange={onChangeCert}
              getOptionLabel={(option) => option.CN}
              renderOption={renderOption}
              renderInput={(params) => <TextField {...params} label="Выберите сертификат" />}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ my: 3 }}
              className={loading ? 'crpt-submit crpt-load' : 'crpt-submit'}
              disabled={!submitOn}
            >
              {loading ? '' : 'войти'}
            </Button>
            <Grid container>
              <Grid item xs>
                <span style={{ fontSize: 'small' }}>сайт </span>
                <Link
                  href="https://xn--80ajghhoc2aj1c8b.xn--p1ai/"
                  variant="body2"
                  sx={{ color: 'secondary.main' }}
                >
                  Честный ЗНАК
                </Link>
              </Grid>
              <Grid item>
              <Link
                  href="#"
                  variant="body2"
                  sx={{ color: 'secondary.main' }}
                >
                  <HelpOutlineIcon sx={{ m: 0.5, fontSize: 'medium', verticalAlign: 'top' }}/>
                  Как войти по УКЭП?
                </Link>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}