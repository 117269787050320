export default {
    cisStatus: {
        EMITTED: 'Выпущен',
        APPLIED: 'Получен',
        INTRODUCED: 'В обороте',
        WRITTEN_OFF: 'Списан',
        RETIRED: 'Выбыл',
        DISAGGREGATION: 'Расформирован'
    },
    gtin: {
        '04670142890041': '04670142890041 - 19 л.',
        '04670142890034': '04670142890034 - 5 л.',
        '04670142890027': '04670142890027 - 0,5 л.',
    },
    certificate_types: {
        CONFORMITY_CERTIFICATE: 'Сертификат соответствия',
        CONFORMITY_DECLARATION: 'Декларация о соответствии',
        STATE_REGISTRATION_CERTIFICATE: 'Свидетельство о государственной регистрации'
    },
    actions: {
        RETAIL: 'Розничная продажа',
        OWN_USE: 'Использование для собственных нужд',
        DESTRUCTION: 'Уничтожение',
        DONATION: 'Безвозмездная передача',
        EXPIRATION: 'Истечение срока годности',
        // KM_DESTROYED: 'Уничтожение',
        // KM_LOST: 'Утрата',
        // KM_SPOILED: 'Испорчен',
        LOSS: 'Утрата',
        OTHER: 'Прочее',
        PRODUCTION_USE: 'Использование для производственных целей',
        STATE_CONTRACT: 'Продажа по государственному (муниципальному) контракту',
        STATE_SECRET: 'Продажа по сделке с государственной тайной',
        UTILIZATION: 'Утилизация',
        VENDING: 'Продажа через вендинговый аппарат'
    },
    documentTypes: {
        RECEIPT: 'Кассовый чек',
        SALES_RECEIPT: 'Товарный чек',
        CONSIGNMENT_NOTE: 'Товарная накладная',
        CUSTOMS_DECLARATION: 'Таможенная декларация',
        UTD: 'Универсальный передаточный документ',
        DESTRUCTION_ACT: 'Акт уничтожения (утраты/утилизации)',
        OTHER: 'Прочее'
    }
};
