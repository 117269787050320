import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import codesActions from "./codesActions";
import dictionary from "./dictionary";
import db from "./db";

const PaperComponent = props => <Draggable
    handle="#draggable-dialog-title"
    cancel={'[class*="MuiDialogContent-root"]'}
>
    <Paper {...props} />
</Draggable>

export default function CodesPrint(props) {
    const { bDialogOpen, closeDialog } = props;
    const { unPrintedSet } = useOutletContext();
    const [selectionBase, setSelectionBase] = useState([]);
    const [selectionModel, setSelectionModel] = useState(selectionBase);
    const [production_date, setDate] = useState(new Date().toISOString().slice(0, 10));
    const codesCountMax = selectionBase.length;

    useEffect(() => {
        setSelectionBase(Array.from([ ...unPrintedSet ]));
        setSelectionModel(Array.from([ ...unPrintedSet ]));
    }, [unPrintedSet])

    function countChange(ev) {
        setSelectionModel(selectionBase.slice(0, ev.target.value));
    }

    function actions (ev) {
        if (!ev.target.name) return;
        // console.log('pre');
        selectionModel.forEach(cis => unPrintedSet.delete(cis));
        db.put('unprinted', { cises: Array.from(unPrintedSet) });
        codesActions(ev.target.name, selectionModel, { once : { production_date } });
        closeDialog();
    }

    return <Dialog
        open={bDialogOpen}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
    >
        <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{ position: 'absolute', right: 12, top: 12, }}
        >
            <CloseIcon />
        </IconButton>
        <DialogTitle
            id="draggable-dialog-title"
            sx={{ pr: 8, cursor: 'move', width: 500 }}
        >
            Печать кодов
        </DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={3} alignItems="center">
                <Grid item>
                    <TextField
                        type="date"
                        variant="outlined"
                        label='Дата производства'
                        value={production_date}
                        onChange={ev => setDate(ev.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs>
                    <Slider
                        min={1}
                        max={codesCountMax}
                        name='codesCount'
                        value={selectionModel.length}
                        onChange={countChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Количество"
                        type="number"
                        name='codesCount'
                        value={selectionModel.length}
                        inputProps={{ min: 1, max: codesCountMax }}
                        onChange={countChange}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions sx={{ m: 1 }} onClick={actions}>
            <Button variant="contained" name='printCodes'>Печать</Button>
        </DialogActions>
    </Dialog>
}
