import React, { useState, useEffect } from "react";
import { DataGrid, ruRU } from '@mui/x-data-grid';
import { useOutletContext, useNavigate } from "react-router-dom";
import api from "./api";
import Box from '@mui/material/Box';
import dictionary from "./dictionary";

const urlString = '/cises/listV2?pg=water&order=DESC&orderColumn=emd&limit=10000&gtin=';
const cellsFromObj = obj => Object.entries(obj).map(([key, value]) => [key, '?']);
const colsFromObj = obj => Object.entries({ name: 'Продукция', ...obj, sum: 'Всего' })
.map(([key, val]) => ({ field: key, headerName: val, width: key === 'name' ? 210 : 150 }));

export function Stat() {
    const { setUrlOpts, unPrintedSet } = useOutletContext();
    const rowsFromObj = obj => Object.entries({ ...obj, total: 'Итого' })
    .map(([key, val]) => ({
        id: key, name: val, sum: 0, ...Object.fromEntries(cellsFromObj(dictionary.cisStatus))
    }));

    const [statisticData, setStatisticData] = useState(rowsFromObj(dictionary.gtin));
    const navigate = useNavigate();
    const cisStatusKeys = Object.keys(dictionary.cisStatus);
    const toFilter = cell => {
        setUrlOpts({
            cisStatus: cell.field in dictionary.cisStatus && cell.field,
            gtin: cell.id in dictionary.gtin && cell.id,
            limit: 10000
        });
        navigate("/codes");
    }
    
    useEffect(() => {
        Object.keys(dictionary.gtin).forEach(gtin => cisStatusKeys
            .forEach(status => status == 'PRINTED' || api.get(urlString + gtin + '&cisStatus=' + status)
                .then(res => setStatisticData(prev => {
                    const will = prev.slice();
                    const currentRow = will.find(row => row.id === gtin);
                    const totalRow = will.find(row => row.id === 'total');

                    currentRow[status] = res.results.length;
                    // if (status == 'APPLIED') {
                    //     currentRow.PRINTED = res.results.filter(row => !unPrintedSet.has(row.cis)).length;
                    //     totalRow.PRINTED = prev.reduce((sum, row, i) => isNaN(sum)
                    //         ? 0 : i < prev.length - 1 ? sum + row.PRINTED : sum, 0);
                    // }
                    currentRow.sum = cisStatusKeys.reduce(
                        (sum, cisStatus) => isNaN(sum) ? 0 : sum + currentRow[cisStatus], 0);
                    totalRow[status] = prev.reduce((sum, row, i) => isNaN(sum)
                        ? 0 : i < prev.length - 1 ? sum + row[status] : sum, 0);
                    totalRow.sum = cisStatusKeys.reduce(
                        (sum, cisStatus) => isNaN(sum) ? 0 : sum + totalRow[cisStatus], 0);

                    return will;
                }))
            )
        )
    }, []);

    return (
        <Box sx={{ height: '50vh', m: 1 }}>
            <DataGrid
                rows={statisticData}
                columns={colsFromObj(dictionary.cisStatus)}
                aria-label='Total'
                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                hideFooter
                onCellClick={toFilter}
            />
        </Box>
    );
}
